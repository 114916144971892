<template>
  <div id="StudentGrow">
    <div class="info">
      <img src="@/assets/images/scene/teacher_banner_bg.png" alt="" />
      <div>
        <h2>应用场景 <span>「</span> 教师专业发展 <span>」</span></h2>
        <!-- <p>Application Scenario-Teacher Professional Development</p> -->
      </div>
    </div>
    <h2 class="applysc">应用场景</h2>
    <!-- <p>Application Scenario</p> -->
    <div class="a_s">
      <div class="a_s_box">
        <img src="@/assets/images/scene/teacher_a_s_n.png" alt="" />
        <div class="content">
          晓羊教师专业发展平台，是在充分调研地方教师及各级教育行政机构状态及需求的基础上，吸收世界各地教师评价先进理念和方式方法，通过学生评教、教学评价、成绩档案等系列教师画像产品，对教师专业水平进行数据量化分析，帮助学校打造和完善教师成长档案，推动教师的持续成长与发展。教师成长档案功能特色：完善的教师发展数据、全面的综合分析方式。位成绩档案体系管理。教学评价功能特色：每节课评价痕迹；可查看、可汇总；可统计、可分析；教学相长有据可查。学生评教功能特色：设置多元教师评价指标，满足多类教学评教需求，多维评教为教师管理减负增效。
        </div>
      </div>
    </div>
    <h2>案例</h2>
    <!-- <p>Case Study</p> -->
    <div class="c_s">
      <h2>武侯实验区教育综合改革</h2>
      <p>
        成都市武侯区教育局以习近平新时代中国特色社会主义思想为指导，坚持党的教育方针，把“立德树人”作为教育的根本任务；以深入实践素质教育为战略主题，以创建“智慧教育”为区域特征，以体制机制改革为实践抓手，构建区域特色校本化课程体系建设，构建德智体美劳全面发展的教育体系，努力培养时代新人。
        基于此，北京晓羊教育集团在武侯区“五育融合”特色课程建设规划思路的基础上，协助武侯区开展课程建设理论指导和实践探索，协助启动研制规范的区域学校课程建设三年规划。协助围绕课程规划开发课程指南、设计课程纲要，根据需求协助打造区域课程建设灯塔学校；指导校本课程特色化建设的创意设计和深度推进，促进学校课程体系建设；总结课程建设过程中的经验，提炼课程建设成果，评选一批多样化的特色课程群，培养一批课程开发建设骨干，进一步提升学校育人水平。
      </p>
      <ul class="c_s_img">
        <li><img src="@/assets/images/scene/teacher_c_s1.png" alt="" /></li>
        <li><img src="@/assets/images/scene/teacher_c_s2.png" alt="" /></li>
        <li><img src="@/assets/images/scene/teacher_c_s3.png" alt="" /></li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StudentGrow',
  data() {
    return {}
  },
  mounted() {
    this.$store.commit('setPcHeaderIsTransparent', 'transparent')
  },
}
</script>

<style lang="scss" scoped>
%StudentGrow {
  h1,
  h2,
  h3,
  h4 {
    color: #333;
  }
  width: 100%;
  position: relative;
  background: #f6f5f5;
  @include px2vw('padding-bottom', 140);
  // @include px2vw('height', 1255);
  .info {
    position: relative;
    img {
      width: 100%;
    }
    div {
      text-align: center;
      @include px2vw('width', 880);
      @include px2vw('padding', 18);
      @include px2vw('padding-bottom', 60);
      background: rgba(0, 0, 0, 0.3);
      color: $--color-white;
      text-shadow: 0px 2px 12px rgba(0, 0, 0, 0.5);
      position: absolute;
      top: 37%;
      left: 26%;
      h2 {
        @include px2vw('font-size', 56);
        font-weight: normal;
        margin: 32px 0 0;
        // margin-top: 32px;
        letter-spacing: 4px;
        color: $--color-white;
        span {
          @include px2vw('font-size', 32);
          vertical-align: top;
          font-weight: bold;
        }
        span:nth-child(2) {
          vertical-align: bottom;
        }
      }
      p {
        margin: 0;
        @include px2vw('font-size', 33);
        @include px2vw('margin-top', 25);
        letter-spacing: 1px;
      }
    }
  }
  > h2 {
    font-size: 32px;
    text-align: center;
    margin-top: 40px;
  }
  > p {
    text-align: center;
    font-size: 24px;
    color: #333333;
    position: relative;
    &::after {
      content: ' ';
      width: 66px;
      height: 3px;
      display: inline-block;
      position: absolute;
      left: 48%;
      margin-top: 45px;
      background: $--color-primary;
    }
  }
  .a_s {
    width: 90%;
    max-width: 1320px;
    // height: 400px;
    margin: 50px auto;
    // background: #f2f2f2 url('~@/assets/images/scene/teacher_a_s.png') no-repeat;
    // background-size: 100% 100%;
    // position: relative;
    // background-position: -45px;
    padding: 50px 0;
    display: flex;
    align-items: center;
    background-color: #f2f2f2;
    .a_s_box {
      background: #f2f2f2;
      margin: 0 60px;
      // padding: 70px 50px 50px 50px;
      z-index: 2;
      img {
        // @include px2vw('width', 440);
        width: 35%;
        vertical-align: middle;
      }
      .content {
        margin-left: 5%;
        display: inline-block;
        width: 60%;
        line-height: 32px;
        font-size: 18px;
        text-align: justify;
        color: #666;
        vertical-align: middle;
      }
    }
  }
  .c_s {
    width: 90%;
    margin: 0 auto;
    max-width: 1320px;

    h2 {
      text-align: center;
      font-size: 40px;
      font-weight: normal;
      margin-top: 50px;
      margin-bottom: 50px;
    }
    p {
      line-height: 32px;
      font-size: 18px;
      text-align: justify;
      color: #666;
    }
    .c_s_img {
      display: flex;
      justify-content: space-between;
      width: 100%;
      flex-wrap: wrap;
      @include px2vw('margin-top', 90);

      li {
        width: 30%;
        img {
          width: 100%;
        }
      }
    }
  }
}
.view-pc > #StudentGrow {
  @extend %StudentGrow;
  padding-bottom: 100px;
  > h2 {
    font-size: 36px;
  }
  h2.applysc {
    margin-top: 50px;
  }
  .c_s {
    &_img {
      margin-top: 50px !important;
    }
  }
  @media screen and (max-width: 1100px) {
    .a_s {
      width: calc(90% - 120px);
      // height: 330px;
      .content {
        font-size: 16px;
        top: 6%;
        line-height: 32px;
      }
    }
    .c_s {
      width: 90%;
      h2 {
        margin-top: 50px;
      }
      p {
        font-size: 22px;
      }
      .c_s_img {
        li {
          width: 100%;
          margin: 10px auto 0;
          // margin-top: 10px;
        }
      }
    }
  }
  @media (min-width: 570px) and(max-width:1440px) {
    .c_s {
      h2 {
        margin-top: 40px;
        margin-bottom: 20px;
      }
    }
  }
}

.view-mobile > #StudentGrow {
  @extend %StudentGrow;
  // @include px2vw('height', 3000);
  > h2 {
    font-size: 22px !important;
  }
  .info {
    margin-top: 44px;
    height: 153px;
    overflow: hidden;
    display: flex;
    div {
      width: 100vw;
      height: 75%;
      padding: 10% 0 0;
      // padding-top: 10%;
      top: 0;
      left: 0;
      h2 {
        font-size: 22px;
        margin-top: 15px;
      }
      p {
        font-size: 10px;
      }
    }
  }
  > p {
    font-size: 14px;

    &::after {
      left: 40% !important;
      margin-top: 35px;
    }
  }
  .student-grow-box {
    margin-top: 40px;
  }
  .a_s {
    background: #f2f2f2;
    padding: 0 20px;
    width: calc(90% - 40px);
    height: 100%;
    margin: 30px 5% 0;
    // margin-bottom: 0;
    flex-wrap: wrap;
    .a_s_box {
      width: 90%;
      padding: 30px 20px;
      margin: 0 !important;
      display: flex;
      flex-wrap: wrap;
      img {
        width: 100%;
      }
      .content {
        font-size: 14px;
        line-height: 28px;
        width: 100%;
        margin-left: 0;
        margin-top: 20px;
      }
    }
  }
  .c_s {
    width: 90%;

    h2 {
      margin-top: 40px;
      font-size: 24px;
      margin-bottom: 25px;
    }
    p {
      font-size: 14px;
      line-height: 28px;
      text-align: justify;
      margin-bottom: 30px;
    }
    .c_s_img {
      margin-bottom: 0;
      li {
        width: 100%;
        margin: 0 auto 30px;
        // margin-top: 10px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    > p {
      &::after {
        left: 45%;
        margin-top: 35px;
      }
    }
  }
}
</style>
